const API_BASE_URL = 'https://api.themoviedb.org/3';
const API_KEY = '38c007f28d5b66f36b9c3cf8d8452a4b';


const basicFetch = async (url) => {
    const req = await fetch(`${API_BASE_URL}${url}?language=pt-BR&api_key=${API_KEY}`);
    const json = await req.json();
    return json;
}



export default {

    getHomeList: async () => {
        return [
            {
                slug: 'originals',
                title: 'Originais do Netflix',
                items: await basicFetch(`/discover/tv?with_network=213`)
            },
            {
                slug: 'trending',
                title: 'Recomentadados para você',
                items: await basicFetch(`/trending/all/week`)
            },
            // {
            //     slug: 'toptread',
            //     title: 'Em alta',
            //     items: []
            // },
            // {
            //     slug: 'action',
            //     title: 'Ação',
            //     items: []
            // },

        ]
    },

    getMovie : async (movieId, type) => {
        let info = {}

        if (movieId) {
            switch(type){
                case 'movie':
                    info = await basicFetch(`/movie/${movieId}`);
                break;
                case 'tv':
                    info =  await basicFetch(`/tv/${movieId}`);
                break;
                default:
                    info = null
                break;
            }
        }

        return info;
    }

}

