import React from "react";
import './Header.css';
import Logo from '../assets/logo.002.png'


export default ({black}) => {
    return (
        <header className={black ? 'black' : ''}>
            <div className="header--logo">
                <a href="">
                {/* <img src="https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg" /> */}
                <img src={Logo} />
                </a>
            </div>

            <div className="header--user">
                <a href="">
                <img src="https://upload.wikimedia.org/wikipedia/commons/0/0b/Netflix-avatar.png" />
                </a>
            </div>
        </header>
    );
}
