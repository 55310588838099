import React, { useEffect, useState } from "react";
import './App.css';
import MovieRow from "./components/MovieRow";
import Tmdb from "./Tmdb";
import FeatureMovie from "./components/FeatureMovie";
import Header from "./components/Header";
import LoadingNetFLix from './assets/loading.gif';

export default () => {

  const [movieList, setMovieList] = useState([]);
  const [featureData, setFeatureData] = useState(null);
  const [blackHeader, setBlackHeader] = useState(false);
  const [tick, setTick] = useState(new Date().toLocaleTimeString())

  useEffect(() => {
    const loadAll = async () => {
      let list = await Tmdb.getHomeList();
      setMovieList(list);
      // console.log(list)
      let originals = list.filter(i => i.slug === 'originals');
      let randomChosen = Math.floor(Math.random() * (originals[0].items.results.length - 1))
      let chosen = originals[0].items.results[randomChosen]
      let chosenInfo = await Tmdb.getMovie(chosen.id, 'tv');
      setFeatureData(chosenInfo)

    }
    loadAll();
  }, []);

  useEffect(() => {
    const scrollListener = () => {
       window.scrollY > 10 ? setBlackHeader(true) : setBlackHeader(false);
    }

    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    }

  }, []);

    setInterval(() => {
      setTick(new Date().toLocaleTimeString());
    }, 1000);  
  

  return (
    <div className="page">
    <Header black={blackHeader} />


      {featureData &&
        <FeatureMovie item={featureData} />
      }

      <section className="lists">
        {movieList.map((item, key) => (
          <MovieRow key={key} title={item.title} items={item.items} />
        ))}
      </section>


      <footer>
        Powered by <span role="img" aria-label="coração"></span>
        <h5>{tick}</h5>
      </footer>

          {movieList.length <=0 && 
          <div className="loading" >
            <img src={LoadingNetFLix} />
          </div>
        }
    </div>
  );
}